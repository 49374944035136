import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import Localization from './container/localization';
import 'intl';
import 'intl/locale-data/jsonp/en';

ReactDOM.render(<Localization />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
