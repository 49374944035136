import React, { Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import it from 'react-intl/locale-data/it';
import fr from 'react-intl/locale-data/fr';
import ar from 'react-intl/locale-data/ar';
import de from 'react-intl/locale-data/de';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import ru from 'react-intl/locale-data/ru';
import tr from 'react-intl/locale-data/tr';
import vi from 'react-intl/locale-data/vi';
import { JoinRoom } from './joinRoom';
import { Router, Route, Switch } from 'react-router-dom';
import { GoogleLogin } from './googleLogin';
import { SsoLogin } from './ssoLogin';
import { chooseLocale } from '../functions';
import { RecurlySubscription } from './recurlySubscription';
import { Invitation } from './invitation';
import { Referrals } from './referrals';
import env from '../config/env';

const createHistory = require('history').createBrowserHistory;

addLocaleData([
  ...en,
  ...zh,
  ...ja,
  ...ko,
  ...it,
  ...fr,
  ...ar,
  ...de,
  ...es,
  ...pt,
  ...ru,
  ...tr,
  ...vi,
]);

const history = createHistory();

const language = navigator.language;

class Localization extends Component {
  render() {
    return (
      <IntlProvider locale={language} messages={chooseLocale(language)}>
        {/* 
        处理link:
        http://m.teamlink.co
        http://m.teamlink.co/${roomid}
        http://m.teamlink.co/${roomid}?p=${password}
         */}
        <Router history={history}>
          <Switch>
            <Route exact path={`${env.publicPath}/`} component={JoinRoom} />
            <Route
              exact
              path={`${env.publicPath}/login`}
              component={GoogleLogin}
            />
            <Route
              exact
              path={`${env.publicPath}/sso-login`}
              component={SsoLogin}
            />
            <Route
              exact
              path={`${env.publicPath}/subscription`}
              component={RecurlySubscription}
            />
            <Route
              exact
              path={`${env.publicPath}/invitation`}
              component={Invitation}
            />
            <Route
              exact
              path={`${env.publicPath}/referrals`}
              component={Referrals}
            />
            {/*
            TODO:path改为 '/joinRoom/:roomid'
            */}
            <Route
              exact
              path={`${env.publicPath}/:roomid`}
              component={JoinRoom}
            />
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

export default Localization;
