import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { intlMessages } from '../localization';
import { Config, getDownloadLink, isSupportedBrowser } from '../functions';
import Assets from '../asserts';
class ToPC extends Component {
  //用户使用browser打开系统时，显示此页面，引导其打开app
  render() {
    const { formatMessage } = this.props.intl;
    const meetingURL = this.props.meetingURL;
    let appHref = getDownloadLink();
    return (
      <div className="open-with-app">
        <img src={Assets.teamlinkLogo} alt="logo" className="teamlinkLogo" />
        {!appHref ? (
          <div className="download-app-wrapper">
            <div className="download-dsp">
              {formatMessage(intlMessages.osIsNotSupported, {
                appName: Config.appName,
              })}
            </div>
            <br />
            <div className="download-page">
              <a href="https://teamlink.co/download.html">
                {formatMessage(intlMessages.supportedOs)}
              </a>
            </div>
          </div>
        ) : (
          <div className="download-app-wrapper">
            <div className="download-dsp">
              {formatMessage(intlMessages.seeDialog)}&nbsp;
            </div>
            <div className="open-dsp">
              {formatMessage(intlMessages.noPromptThenClick)}
            </div>

            <a href={this.props.urlScheme}>
              <button className="open-btn">
                {' '}
                {formatMessage(intlMessages.joinMeeting)}
              </button>
            </a>

            <div className="divider"></div>
            <div className="download">
              <img src={Assets.downloadIcon} alt="download-icon" />
              <span className="link">
                {formatMessage(intlMessages.openAppOrDownload)}{' '}
                <a href={appHref}>{Config.appName}</a>
              </span>
            </div>
          </div>
        )}
        <br />
        {meetingURL && (
          <div className="download-page">
            {formatMessage(intlMessages.or)}&nbsp;
            <a href={meetingURL}>
              {formatMessage(intlMessages.joinFromYourBrowser)}
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(ToPC);
