import React, { Component } from "react";
import { getScheme } from "../../functions";
import { injectIntl } from "react-intl";
import { intlMessages } from "../../localization";
import Assets from "../../asserts";

// recurly支付完成后重定向页面
class RecurlySubscription extends Component {
  openAPP = () => {
    const urlScheme = getScheme("", { recurlyPay: true });
    console.log("urlScheme", urlScheme);
    window.location.href = urlScheme;
  };
  render() {
    const { formatMessage } = this.props.intl;
    setTimeout(this.openAPP, 200);
    return (
      <div className="recurly-subscription-container">
        <img className="success-icon" src={Assets.success} />
        <div className="title">{formatMessage(intlMessages.thankYou)}</div>
        <div className="subtitle">
          {formatMessage(intlMessages.purchasedSuccessfully)}
        </div>
        <div className="extra-description">
          {formatMessage(intlMessages.dontSeePrompt)}{" "}
          <span className="to-teamlink" onClick={this.openAPP}>
            {formatMessage(intlMessages.returnToTeamLink)}
          </span>
        </div>
      </div>
    );
  }
}

export default injectIntl(RecurlySubscription);
