import React, { Component } from 'react';
import {
  isAndroidDesktopMode,
  getScheme,
  detectOS,
  Config,
  mapQueryStringToObj,
  getWebOrigin,
} from '../../functions';
import ToPc from '../../components/toPc';
import ToMobile from '../../components/toMobile';

class JoinRoom extends Component {
  render() {
    const roomId = this.props.match.params.roomid;
    console.log('roomId', roomId);
    if (roomId === 'login' || roomId === 'subscription') {
      //TODO:删除判断
      return <div></div>;
    }
    const queryString = mapQueryStringToObj(window.location.href);
    const password = queryString['p'];
    const roleKey = queryString['r']; //线上活动 https://docs.google.com/document/d/1nOMqzUbqFV8_350JVYbUTxqjT28XsGkIk8CpXMxnJZE/edit#heading=h.43ui5h5zegol
    const t = queryString['t'];
    const accountServer = queryString['a'];
    const webOrigin = queryString['w'];

    //要跳转到的链接地址
    const urlScheme = getScheme('', {
      roomId,
      password,
      roomName: roomId,
      roleKey,
      accountServer,
      webOrigin,
    }); // roomName兼容electron老代码

    console.log('urlScheme', urlScheme);

    const os = detectOS();
    let meetingURL = null;
    let renderBody;

    if (t === 'w') {
      // t=w的意思是target=web，说明跳转到 web，
      meetingURL = getWebOrigin(
        roomId,
        password,
        roleKey,
        accountServer,
        webOrigin,
      );
      console.log('toweb,meetingURL:', meetingURL);
    }

    setTimeout(() => {
      if (os !== Config.supportedOS.ios && t !== 'w') {
        window.location.href = urlScheme;
      }
    }, 200);
    if (os === Config.supportedOS.ios || os === Config.supportedOS.android) {
      renderBody = <ToMobile os={os} urlScheme={urlScheme} />;
    } else {
      renderBody = (
        <ToPc os={os} urlScheme={urlScheme} meetingURL={meetingURL} />
      );
    }

    return renderBody;
  }
}

export default JoinRoom;
