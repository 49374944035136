import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  appName: {
    id: 'appName',
    description: '',
    defaultMessage: 'TeamLink',
  },
  seeDialog: {
    id: 'seeDialog',
    description:
      'when user open app without our electron app, they will see this message',
    defaultMessage:
      'If you see the dialog, click "Open" to launch the TeamLink app.',
  },
  noPromptThenClick: {
    id: 'noPromptThenClick',
    description: '',
    defaultMessage:
      'If there is no prompt, please click on the button below to join the meeting',
  },
  here: {
    id: 'here',
    description: '',
    defaultMessage: 'here',
  },
  openAppOrDownload: {
    id: 'openAppOrDownload',
    description: '',
    defaultMessage: 'or download and install',
  },
  osIsNotSupported: {
    id: 'osIsNotSupported',
    description: '',
    defaultMessage: '{appName} - Video and Web Conference for Free.',
  },
  supportedOs: {
    id: 'supportedOs',
    description: '',
    defaultMessage: 'Available for Windows, Mac, iOS and Android.',
  },
  continueToApp: {
    id: 'continueToApp',
    description: '',
    defaultMessage: 'Continue to the app',
  },
  mobileText1: {
    id: 'mobileText1',
    description: '',
    defaultMessage:
      "If you don't have TeamLink app on this device, please download and install it first.",
  },
  mobileText2: {
    id: 'mobileText2',
    description: '',
    defaultMessage:
      'After you installed the TeamLink app, come back to this page and click the "Join Meeting" button to join the meeting.',
  },
  mobileText3: {
    id: 'mobileText3',
    description: '',
    defaultMessage:
      'If the TeamLink app is installed, please click below button to join the meeting.',
  },
  joinMeeting: {
    id: 'joinMeeting',
    description: '',
    defaultMessage: 'Join Meeting',
  },
  clickRightCorner: {
    id: 'clickRightCorner',
    description: '',
    defaultMessage: 'Tab {dot} on the top right corner',
  },
  selectBrowserToOpenLink: {
    id: 'selectBrowserToOpenLink',
    description: '',
    defaultMessage: 'Then select {text}',
  },
  browser: {
    id: 'browser',
    description: '',
    defaultMessage: 'Open with Browser',
  },
  signInWidthGoogle: {
    id: 'signInWidthGoogle',
    description: '',
    defaultMessage: 'Sign in with Google',
  },
  signInWidthSso: {
    id: 'signInWidthSso',
    description: '',
    defaultMessage: 'Sign in with SSO',
  },
  teamlinkStartLater: {
    id: 'teamlinkStartLater',
    description: '',
    defaultMessage:
      'Click "Open TeamLink" if prompted by your browser. If TeamLink\n doesn\'t launch, please click the button below to open the app.',
  },
  launchTeamlink: {
    id: 'launchTeamlink',
    description: '',
    defaultMessage: 'Open TeamLink',
  },
  installFailed: {
    id: 'installFailed',
    description: '',
    defaultMessage: 'Alternatively, you can',
  },
  joinMeetingUsingBrowser: {
    id: 'joinMeetingUsingBrowser',
    description: '',
    defaultMessage: 'join from browser',
  },
  thankYou: {
    id: 'thankYou',
    description: '',
    defaultMessage: 'Thank You',
  },
  purchasedSuccessfully: {
    id: 'purchasedSuccessfully',
    description: '',
    defaultMessage:
      'You have successfully purchased your TeamLink subscription.',
  },
  dontSeePrompt: {
    id: 'dontSeePrompt',
    description: '',
    defaultMessage: 'Don’t see browser prompt?',
  },
  returnToTeamLink: {
    id: 'returnToTeamLink',
    description: '',
    defaultMessage: 'Return to TeamLink',
  },
  contactsRequest: {
    id: 'contactsRequest',
    description: '',
    defaultMessage: 'Contacts request',
  },
  addYouAsContact: {
    id: 'addYouAsContact',
    description: '',
    defaultMessage:
      '{displayName} would like to add you as a contact on TeamLink.',
  },
  alreadyInstalled: {
    id: 'alreadyInstalled',
    description: '',
    defaultMessage: 'Already have TeamLink installed?',
  },
  openApp: {
    id: 'openApp',
    description: '',
    defaultMessage: 'Open the app',
  },
  toAcceptInvitation: {
    id: 'toAcceptInvitation',
    description: '',
    defaultMessage: 'to accept the invitation.',
  },
  otherwiseDownload: {
    id: 'otherwiseDownload',
    description: '',
    defaultMessage: 'Otherwise, download to proceed.',
  },
  download: {
    id: 'download',
    description: '',
    defaultMessage: 'Download',
  },
  invitingTryTeamlink: {
    id: 'invitingTryTeamlink',
    description: '',
    defaultMessage:
      '{nickname} is inviting you to try the TeamLink video conference app.',
  },
  downloadAndInstall: {
    id: 'downloadAndInstall',
    description: '',
    defaultMessage: 'Download and install TeamLink.',
  },
  signUpForFree: {
    id: 'signUpForFree',
    description: '',
    defaultMessage: 'Sign up for a free account.',
  },
  comeBackConfirmReferrer: {
    id: 'comeBackConfirmReferrer',
    description: '',
    defaultMessage:
      'Come back here to confirm your referrer and get reward points for both of you.',
  },
  confirmReferrer: {
    id: 'confirmReferrer',
    description: '',
    defaultMessage: 'Confirm Referrer',
  },
  or: {
    id: 'or',
    description: '',
    defaultMessage: 'or',
  },
  joinFromYourBrowser: {
    id: 'joinFromYourBrowser',
    description: '',
    defaultMessage: 'join from your browser',
  },
});
