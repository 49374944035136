import React, { Component } from 'react';
import {
  getScheme,
  mapQueryStringToObj,
  isWeixin,
  getDownloadLink,
  base64UrlDecode,
} from '../../functions';
import Assets from '../../asserts';
import { Base64 } from 'js-base64';
import { injectIntl, FormattedMessage } from 'react-intl';
import { intlMessages } from '../../localization';

class Referrals extends Component {
  render() {
    const { formatMessage } = this.props.intl;

    const queryString = mapQueryStringToObj(window.location.href);
    const nickname = base64UrlDecode(queryString['n']);
    const username = base64UrlDecode(queryString['u']);

    const urlScheme = getScheme('referrals', {
      u: username,
    });
    console.log('urlScheme', urlScheme);

    return (
      <div className="referrals-container referrals">
        {isWeixin() && (
          <div className="wechat-mask">
            <div>
              <div className="click-right-top">
                <FormattedMessage
                  id="clickRightCorner"
                  values={{
                    dot: <b>. . .</b>,
                  }}
                />
              </div>
              <div className="slect-browser">
                <FormattedMessage
                  id="selectBrowserToOpenLink"
                  values={{
                    text: <b>{formatMessage(intlMessages.browser)}</b>,
                  }}
                />
              </div>
            </div>
            <img className="arrow" src={Assets.arrow} />
          </div>
        )}
        <div className="referrals-main">
          <div>
            <img src={Assets.teamlinkLogo} />
          </div>
          <div className="referrals-main-title">
            {formatMessage(intlMessages.invitingTryTeamlink, {
              nickname,
            })}
          </div>
          <p>1. {formatMessage(intlMessages.downloadAndInstall)}</p>
          <div
            className="referrals-main-button"
            onClick={() => {
              window.location.href = getDownloadLink();
            }}
          >
            {formatMessage(intlMessages.download)}
          </div>
          <p>2. {formatMessage(intlMessages.signUpForFree)}</p>
          <p>3. {formatMessage(intlMessages.comeBackConfirmReferrer)}</p>
          <div
            className="referrals-main-button"
            onClick={() => {
              window.location.href = urlScheme;
            }}
          >
            {formatMessage(intlMessages.confirmReferrer)}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Referrals);
