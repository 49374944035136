import {
  zh_CN,
  zh_TW,
  en_US,
  ja,
  ko,
  it,
  fr,
  ar,
  de,
  es,
  pt,
  ru,
  tr,
  vi,
} from './localization';

const Config = {
  supportedOS: {
    windows: 'Win',
    macOS: 'Mac',
    ios: 'IOS',
    android: 'Android',
    linux: 'Linux',
  },
  pcSchemeV2: (schemeKey, schemeValues) => {
    return `teamlink://${schemeKey}?${mapSchemeObjToQueryString(schemeValues)}`;
  },
  androidSchemeV2: schemeValues => {
    return `app.cybrook.teamlink://app.cybrook.teamlink?${mapSchemeObjToQueryString(
      schemeValues,
    )}`;
  },
  // TeamLink controller
  androidCtrlSchemeV2: schemeValues => {
    return `app.cybrook.controller://app.cybrook.controller?${mapSchemeObjToQueryString(
      schemeValues,
    )}`;
  },
  // TeamLink business
  androidBusinessSchemeV2: schemeValues => {
    return `app.cybrook.teamlink.business://app.cybrook.teamlink.business?${mapSchemeObjToQueryString(
      schemeValues,
    )}`;
  },
  // TeamLink consumer
  androidConsumerSchemeV2: schemeValues => {
    return `app.cybrook.teamlink.consumer://app.cybrook.teamlink.consumer?${mapSchemeObjToQueryString(
      schemeValues,
    )}`;
  },
  iosSchemeV2: schemeValues => {
    return `app.cybrook.teamlink://?${mapSchemeObjToQueryString(schemeValues)}`;
  },
  // TeamLink ios controller
  iosCtrlSchemeV2: schemeValues => {
    return `com.cybrook.teamlink.ctrl://?${mapSchemeObjToQueryString(
      schemeValues,
    )}`;
  },
  appName: 'TeamLink',
  webOrigin: {
    release: 'https://meet.teamlink.co/',
    dev: 'https://meet-dev.teamlink.co/',
    daily: 'https://meet-daily.teamlink.co/',
    daily1: 'https://meet-daily1.teamlink.co/',
  },
};

export { Config };

//参数编码 url编码
export function encodeParam(param) {
  return encodeURIComponent(param);
}
//参数编码 url解码
export function decodeParam(param) {
  return decodeURIComponent(param);
}
// Base64 URL-safe encoding
export function base64UrlEncode(str) {
  let base64 = btoa(str);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

// Base64 URL-safe decoding
export function base64UrlDecode(str) {
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  while (str.length % 4) {
    str += '=';
  }
  return atob(str);
}
// 判断安卓手机是不是开启了desktop mode
export function isAndroidDesktopMode() {
  var webkitVer = parseInt(
    /WebKit\/([0-9]+)|$/.exec(navigator.appVersion)[1],
    10,
  ); // also matches AppleWebKit
  var isGoogle = webkitVer && navigator.vendor.indexOf('Google') === 0; // Also true for Opera Mobile and maybe others
  var isAndroid = isGoogle && navigator.userAgent.indexOf('Android') > 0; // Careful - Firefox and Windows Mobile also have Android in user agent
  var androidDesktopMode =
    !isAndroid &&
    isGoogle &&
    navigator.platform.indexOf('Linux a') === 0 &&
    'ontouchstart' in document.documentElement;
  console.log(androidDesktopMode);
  return androidDesktopMode;
}

export function detectOS() {
  const isWin =
    navigator.platform === 'Win32' || navigator.platform === 'Windows';
  if (isWin) return Config.supportedOS.windows;

  // https://juejin.cn/post/6865658712665620494
  const isIOS =
    /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  if (isIOS) return Config.supportedOS.ios;

  const isMac =
    navigator.platform === 'Mac68K' ||
    navigator.platform === 'MacPPC' ||
    navigator.platform === 'Macintosh' ||
    navigator.platform === 'MacIntel';
  if (isMac) return Config.supportedOS.macOS;
  // const isUnix = navigator.platform === 'X11' && !isWin && !isMac;
  // if (isUnix) return 'Unix';
  const isAndroid =
    /(Android)/i.test(navigator.userAgent) || isAndroidDesktopMode();
  if (isAndroid) return Config.supportedOS.android;
  //android设备也会包含Linux，先判断是否为Android
  const isLinux = String(navigator.platform).indexOf('Linux') > -1;
  if (isLinux) return Config.supportedOS.Linux;
  return 'other';
}

export function getScheme(schemeKey, schemeValues) {
  const os = detectOS();
  const queryString = mapQueryStringToObj(window.location.href);
  const loginType = queryString.type;
  console.log('loginType:', loginType, '|| os:', os);

  if (loginType === 'iosCtrl') {
    // TeamLin Controller ios端
    return Config.iosCtrlSchemeV2(schemeValues);
  }

  if (os === Config.supportedOS.ios) {
    schemeValues.action = schemeKey;
    return Config.iosSchemeV2(schemeValues);
  } else if (os === Config.supportedOS.android) {
    schemeValues.action = schemeKey;
    if (loginType === 'androidCtrl') {
      // TeamLin Controller android端
      return Config.androidCtrlSchemeV2(schemeValues);
    } else if (loginType === 'androidBiz') {
      // TeamLin android端 商业版
      return Config.androidBusinessSchemeV2(schemeValues);
    } else if (loginType === 'androidCs') {
      // TeamLin android端 consumer
      return Config.androidConsumerSchemeV2(schemeValues);
    } else {
      return Config.androidSchemeV2(schemeValues);
    }
  } else {
    return Config.pcSchemeV2(schemeKey, schemeValues);
  }
}

export function mapQueryStringToObj(url) {
  let theRequest = {};
  if (url.indexOf('?') !== -1) {
    let str = window.location.search.split('?')[1];
    let strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeParam(strs[i].split('=')[1]); //参数解码；调用该方法得到的url参数不需要再解码
    }
  }
  return theRequest;
}

export function getGoogleIdToken(url) {
  let idToken = '';
  if (url.indexOf('#') !== -1) {
    let str = url.split('#')[1];
    let strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      let keyValueArr = strs[i].split('=');
      if (keyValueArr[0] === 'id_token') {
        idToken = keyValueArr[1];
      }
    }
  }
  return idToken;
}

export function isWeixin() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger';
}

export function isSupportedBrowser() {
  //经过测试macOS,windows,Linux上Chrome浏览器都可用此方法检测
  const isChrome = () => {
    return !!window.chrome;
  };

  const supportedBrowsers = {
    windows: {
      chrome: isChrome,
      //360浏览器现在当成chrome浏览器处理，后期需要区分360和Chrome再修改
      qiHu360Secure: isChrome,
    },
    macOS: {
      chrome: isChrome,
    },
    linux: {
      chrome: isChrome,
    },
  };

  const os = detectOS();
  let supportedBrowserList;
  switch (os) {
    case Config.supportedOS.macOS:
      supportedBrowserList = supportedBrowsers.macOS;
      break;
    case Config.supportedOS.windows:
      supportedBrowserList = supportedBrowsers.windows;
      break;
    case Config.supportedOS.linux:
      supportedBrowserList = supportedBrowsers.linux;
      break;
    case Config.supportedOS.ios:
    case Config.supportedOS.android:
      //android ios 暂不支持
      break;
    default:
      break;
  }
  let isSupportedBrowser = false;
  if (supportedBrowserList) {
    isSupportedBrowser = Object.keys(supportedBrowserList).some(key => {
      return supportedBrowserList[key]();
    });
  }
  return isSupportedBrowser;
}

export function chooseLocale() {
  const languageTag = navigator.language;
  const languagePrefix = languageTag.split('-')[0];
  console.log('languageTag:', languageTag, 'languagePrefix:', languagePrefix);
  switch (languagePrefix) {
    case 'en':
      console.log('is en');
      return en_US;

    case 'zh':
      switch (languageTag) {
        case 'zh-CN':
          return zh_CN;
        case 'zh-TW':
          return zh_TW;
        default:
          return zh_CN;
      }

    case 'ja':
      return ja;
    case 'ko':
      return ko;
    case 'it':
      return it;
    case 'fr':
      return fr;
    case 'ar':
      return ar;
    case 'de':
      return de;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'tr':
      return tr;
    case 'vi':
      return vi;
    default:
      return en_US;
  }
}

export function isMainland() {
  return navigator.language.toLowerCase() === 'zh-cn';
}

export function getDownloadLink() {
  const os = detectOS();
  switch (os) {
    case Config.supportedOS.macOS:
      return 'https://download.teamlink.co/TeamLink.dmg';
    case Config.supportedOS.windows:
      return 'https://download.teamlink.co/TeamLink+Setup.exe';
    case Config.supportedOS.ios:
      return 'https://itunes.apple.com/us/app/teamlink-video-conferencing/id1463506261';
    case Config.supportedOS.android:
      if (isMainland()) {
        return 'https://download.teamlink.co/teamlink.apk';
      } else {
        return 'https://play.google.com/store/apps/details?id=app.cybrook.teamlink';
      }
    default:
      return 'https://download.teamlink.co/TeamLink+Setup.exe';
  }
}

function mapSchemeObjToQueryString(schemeValues) {
  let queryString = '';
  for (let key in schemeValues) {
    if (schemeValues[key]) {
      if (queryString) queryString += '&';
      queryString += `${key}=${encodeParam(schemeValues[key])}`;
    }
  }
  return queryString;
}

//生成webOrigin链接
//https://localhost:3000?i=true
//https://localhost:3000/room/${roomName}?i=true
//https://localhost:3000/room/${roomName}?password=${password}&i=true
//https://localhost:3000/room/${roomName}?password=${password}&r={roleKey}&a={accountServer}&i=true
export function getWebOrigin(roomId, password, roleKey, accountServer, wo) {
  let result = 'https://meet.teamlink.co';
  if (wo) {
    result = Config.webOrigin[wo];
  }
  if (result.endsWith('/')) {
    //删除斜杠 /
    result = result.substring(0, result.length - 1);
  }
  if (roomId) {
    result += `/room/${roomId}`;
    if (password) {
      result += `?password=${password}`;
    }
  }
  if (roleKey) {
    if (!result.includes('?')) {
      result += '?';
    } else {
      result += '&';
    }
    result += `r=${roleKey}`;
  }

  if (accountServer) {
    if (!result.includes('?')) {
      result += '?';
    } else {
      result += '&';
    }
    result += `a=${accountServer}`;
  }

  if (!result.includes('?')) {
    result += '?i=true';
  } else {
    result += '&i=true';
  }

  return result;
}
