import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { intlMessages } from '../../localization';
import { getScheme, getGoogleIdToken, detectOS, Config } from '../../functions';

class GoogleLogin extends Component {
  render() {
    const { formatMessage } = this.props.intl;

    const id_token = getGoogleIdToken(window.location.href);
    const urlScheme = getScheme('', {
      googleIdToken: id_token,
    });
    console.log('GoogleLogin urlScheme', urlScheme);

    let androidClass = '';
    let detailMsg = formatMessage(intlMessages.teamlinkStartLater);
    const os = detectOS();
    if (os === Config.supportedOS.android) {
      androidClass = 'login-container-android';
      detailMsg = detailMsg.replace('\n', '');
    }

    setTimeout(() => {
      window.location.href = urlScheme;
    }, 200);
    return (
      <div className={`login-container ${androidClass}`}>
        <div className="title">
          {formatMessage(intlMessages.signInWidthGoogle)}
        </div>
        <div className="detail-msg">{detailMsg}</div>
        <div
          className="launch-button"
          onClick={() => {
            window.location.href = urlScheme;
          }}
        >
          {formatMessage(intlMessages.launchTeamlink)}
        </div>
      </div>
    );
  }
}

export default injectIntl(GoogleLogin);
