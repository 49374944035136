import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { intlMessages } from '../localization';
import Assets from '../asserts';
import {
  Config,
  isWeixin,
  isMainland,
  getDownloadLink,
  isAndroidDesktopMode,
} from '../functions.js';

class GoToAppMobile extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    const os = this.props.os;
    return (
      <div className="to-mobile">
        {isWeixin() && (
          <div className="wechat-mask">
            <div>
              <div className="click-right-top">
                <FormattedMessage
                  id="clickRightCorner"
                  values={{
                    dot: <b>. . .</b>,
                  }}
                />
              </div>
              <div className="slect-browser">
                <FormattedMessage
                  id="selectBrowserToOpenLink"
                  values={{
                    text: <b>{formatMessage(intlMessages.browser)}</b>,
                  }}
                />
              </div>
            </div>
            <img className="arrow" src={Assets.arrow} />
          </div>
        )}
        <div className="text">{formatMessage(intlMessages.mobileText1)}</div>
        <div className="text">{formatMessage(intlMessages.mobileText2)}</div>
        <div className="btns">
          {os === Config.supportedOS.ios && (
            <div
              onClick={() => {
                window.location.href = getDownloadLink();
              }}
              className="download-btn"
            >
              <img src={Assets.appStore} />
            </div>
          )}
          {os === Config.supportedOS.android && (
            <div
              onClick={() => {
                window.location.href = getDownloadLink();
              }}
              className="download-btn"
            >
              <img src={Assets.googlePlay} />
            </div>
          )}
          {(os === Config.supportedOS.android || isAndroidDesktopMode()) &&
            isMainland() && (
              <div
                onClick={() => {
                  window.location.href = getDownloadLink();
                }}
                className="download-btn"
              >
                <img src={Assets.teamlink} />
              </div>
            )}
        </div>
        <div className="text">{formatMessage(intlMessages.mobileText3)}</div>
        <div
          className="join-btn"
          onClick={() => {
            window.location.href = this.props.urlScheme;
          }}
        >
          {formatMessage(intlMessages.joinMeeting)}
        </div>
      </div>
    );
  }
}

export default injectIntl(GoToAppMobile);
