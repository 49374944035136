const assets = {
  appStore: require('./img-appstore.png'),
  googlePlay: require('./img-googleplay.png'),
  teamlink: require('./img-teamlink.png'),
  arrow: require('./img-arrow.svg'),
  success: require('./img-success.png'),
  teamlinkLogo: require('./logo-teamlink.svg'),
  downloadIcon: require('./ic-download.svg'),
};

export default assets;
