import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { intlMessages } from '../../localization';
import {
  getScheme,
  mapQueryStringToObj,
  detectOS,
  Config,
} from '../../functions';

class SsoLogin extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    const ssoPath = 'sso-login';

    // https://.../sso-login?token=XXXX...
    // =>
    // teamlink://sso-login?path=sso-login&token=XXXX...
    let queryString = mapQueryStringToObj(window.location.href);
    queryString['path'] = ssoPath;
    let urlScheme = getScheme(ssoPath, queryString);
    console.log('SsoLogin urlScheme', urlScheme);

    let androidClass = '';
    let detailMsg = formatMessage(intlMessages.teamlinkStartLater);
    const os = detectOS();
    if (os === Config.supportedOS.android) {
      androidClass = 'login-container-android';
      detailMsg = detailMsg.replace('\n', '');
    }

    setTimeout(() => {
      window.location.href = urlScheme;
    }, 200);
    return (
      <div className={`login-container ${androidClass}`}>
        <div className="title">
          {formatMessage(intlMessages.signInWidthSso)}
        </div>
        <div className="detail-msg">{detailMsg}</div>
        <div
          className="launch-button"
          onClick={() => {
            window.location.href = urlScheme;
          }}
        >
          {formatMessage(intlMessages.launchTeamlink)}
        </div>
      </div>
    );
  }
}

export default injectIntl(SsoLogin);
