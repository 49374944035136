import React, { Component } from 'react';
import {
  getScheme,
  mapQueryStringToObj,
  isWeixin,
  getDownloadLink,
} from '../../functions';
import Assets from '../../asserts';
import { Base64 } from 'js-base64';
import { injectIntl, FormattedMessage } from 'react-intl';
import { intlMessages } from '../../localization';

class Invitation extends Component {
  render() {
    const { formatMessage } = this.props.intl;

    const queryString = mapQueryStringToObj(window.location.href);
    const profileId = queryString['profileId'];
    const userData = queryString['userdata'];
    let displayName = '';
    if (userData) {
      try {
        const decodeUserData = Base64.decode(userData);
        const userInfo = JSON.parse(decodeUserData);
        console.log('User Info:', userInfo);
        if (userInfo.nickname) {
          displayName += userInfo.nickname;
          if (userInfo.email) {
            displayName += ` (${userInfo.email})`;
          }
        } else if (userInfo.email) {
          displayName += userInfo.email;
        }
      } catch (e) {
        console.error(e);
      }
    }
    const urlScheme = getScheme('invitation', {
      profileId,
      userData,
    });
    console.log('urlScheme', urlScheme);

    return (
      <div className="invitation-container invitation">
        {isWeixin() && (
          <div className="wechat-mask">
            <div>
              <div className="click-right-top">
                <FormattedMessage
                  id="clickRightCorner"
                  values={{
                    dot: <b>. . .</b>,
                  }}
                />
              </div>
              <div className="slect-browser">
                <FormattedMessage
                  id="selectBrowserToOpenLink"
                  values={{
                    text: <b>{formatMessage(intlMessages.browser)}</b>,
                  }}
                />
              </div>
            </div>
            <img className="arrow" src={Assets.arrow} />
          </div>
        )}
        <div className="invitation-main">
          <div>
            <img src={Assets.teamlinkLogo} />
          </div>
          <div className="invitation-main-title">
            {formatMessage(intlMessages.contactsRequest)}
          </div>
          <p>{formatMessage(intlMessages.addYouAsContact, { displayName })}</p>
          <p>
            {formatMessage(intlMessages.alreadyInstalled)}{' '}
            <a href={urlScheme}>{formatMessage(intlMessages.openApp)}</a>{' '}
            {formatMessage(intlMessages.toAcceptInvitation)}
          </p>
          <p>{formatMessage(intlMessages.otherwiseDownload)}</p>
          <div
            className="invitation-main-button"
            onClick={() => {
              window.location.href = getDownloadLink();
            }}
          >
            {formatMessage(intlMessages.download)}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Invitation);
